import React, { useState, useEffect } from "react";
import axios from "axios";
import "./weatherWidget.css";
import { ReactComponent as LoadingSpinner } from "../../assets/loading.svg";

const WEATHER_API_KEY = process.env.REACT_APP_WEATHER_API_KEY;

const WeatherWidget = () => {
  const [weather, setWeather] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchWeather = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather`,
        {
          params: {
            lat: latitude,
            lon: longitude,
            units: "metric", // 'imperial' for Fahrenheit
            appid: WEATHER_API_KEY, // Replace with your OpenWeatherMap API key
          },
        }
      );
      setWeather(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const updatePosition = () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const { latitude, longitude } = pos.coords;
        fetchWeather(latitude, longitude);
      },
      (err) => {
        console.error(err);
        setError(err);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    updatePosition(); // Fetch weather on component mount
  }, []);

  if (loading)
    return (
      <div className="loading d-flex bg-dark flex-column  h-100 w-100 overflow-hidden rounded-4">
        <LoadingSpinner />
      </div>
    );
  if (error) return <div>Error fetching weather data</div>;

  const { main, weather: weatherDetails, name, dt, sys } = weather;
  const weatherCondition = weatherDetails[0];
  const currentTime = new Date(dt * 1000).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div
      onClick={updatePosition}
      className="weather-widget rounded-4 position-relative overflow-hidden bg-dark"
    >
      <div className="p-4 position-relative d-flex text-light flex-column align-items-start w-100 h-100">
        <h4 class="mb-0">
          {name}, {sys.country}
        </h4>
        <p class="display-1 my-3">{Math.round(main.temp)}°</p>
        <img
          src={`https://openweathermap.org/img/wn/${weatherCondition.icon}@2x.png`}
          alt={weatherCondition.description}
          class="mt-auto"
        />
        <p className="mb-0">
          {weatherCondition.description} at {currentTime}
        </p>
        <p className="mb-0">
          Feels Like: <strong>{Math.round(main.feels_like)}°C</strong>
        </p>
        <div className="position-absolute top-0 end-0"></div>
      </div>
    </div>
  );
};

export default WeatherWidget;
