import "bootstrap/dist/css/bootstrap.min.css";
import "leaflet/dist/leaflet.css";
import "./App.css";

import MapWidget from "./components/MapWidget/MapWidget";
import WeatherWidget from "./components/WeatherWidget/WeatherWidget";

function App() {
  return (
    <div className="container-fluid bg-pattern vh-100 p-3">
      <div className="row h-100">
        <div className="col-md-6 d-flex p-3">
          <div className="border bg-light rounded-4 w-100 overflow-hidden">
            <MapWidget />
          </div>
        </div>
        <div className="col-md-6 d-flex flex-column p-3">
          <div className="row h-50 mb-3">
            <div className="col-md-6 pe-3 h-100">
              <div className="p-3 border bg-light rounded-4 h-100">Cell 1</div>
            </div>
            <div className="col-md-6 ps-3 h-100">
              <div className="border border-secondary rounded-4 h-100">
                <WeatherWidget />
              </div>
            </div>
          </div>
          <div className="row h-50">
            <div className="col-md-6 pe-3 h-100">
              <div className="p-3 border bg-light rounded-4 h-100">Cell 3</div>
            </div>
            <div className="col-md-6 ps-3 h-100">
              <div className="p-3 border bg-light rounded-4 h-100">Cell 4</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
