import React, { useState, useEffect } from "react";
import ReactMapGL, { Marker, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./mapWidget.css";
import { calculateBearing } from "../../utils/utils";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapWidget = () => {
  const [viewport, setViewport] = useState({
    latitude: 41.195491,
    longitude: -8.700545,
    zoom: 17,
    pitch: 0, // Tilt the map for 3D effect
    bearing: 0, // Rotate the map for better 3D visualization
    width: "100%",
    height: "100%",
  });

  const [position, setPosition] = useState([41.195491, -8.700545]);
  const [previousPosition, setPreviousPosition] = useState(null);

  useEffect(() => {
    const updatePosition = () => {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const newPosition = [pos.coords.latitude, pos.coords.longitude];

          if (previousPosition) {
            const newBearing = calculateBearing(
              previousPosition[0],
              previousPosition[1],
              newPosition[0],
              newPosition[1]
            );
            setViewport((prevViewport) => ({
              ...prevViewport,
              latitude: newPosition[0],
              longitude: newPosition[1],
              bearing: newBearing,
            }));
          } else {
            setViewport((prevViewport) => ({
              ...prevViewport,
              latitude: newPosition[0],
              longitude: newPosition[1],
            }));
          }

          setPreviousPosition(newPosition);
          setPosition(newPosition);
        },
        (err) => {
          console.error(err);
        }
      );
    };

    updatePosition();
    const interval = setInterval(updatePosition, 5000);

    return () => clearInterval(interval);
  }, [previousPosition]);

  return (
    <ReactMapGL
      {...viewport}
      mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
      mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
      onViewportChange={(newViewport) => setViewport(newViewport)}
    >
      <Marker latitude={position[0]} longitude={position[1]}>
        <div className="pulsing-marker"></div>
      </Marker>
      <div style={{ position: "absolute", right: 10, top: 10 }}>
        <NavigationControl />
      </div>
    </ReactMapGL>
  );
};

export default MapWidget;
